import {Component, OnInit} from "@angular/core";
import {ApiService} from '../../api/service/api.service';
import {InvoiceStats} from '../invoice/models/invoice.stats';
import {ConfigService} from '../../core/services';
import {EntityResponseModel} from '../../api/model/shared/Response.model';

@Component({
  selector: 'tj-statistics-charts',
  templateUrl: './statistics-charts.component.html',
})
export class StatisticsChartsComponent implements OnInit {

  protected invoiceStats: InvoiceStats;

  constructor(private api: ApiService, public config: ConfigService) {
  }

  ngOnInit() {
    this.loadStats();
  }

  loadStats(): void {
    this.api.invoice.getStats(null, null, null).subscribe(
      (response: EntityResponseModel<InvoiceStats>) => {
        this.invoiceStats = response.response;
      },
      (err) => {
        console.error(err);
      }
    );
  }

}
