
<div *ngIf="ticket" class="tj-loading-container">
  <div class="grid">
    <div class="col-12 sticky-toolbar">
      <div class="col-12 bg-gray-50 text-right">
        <div class="col-fixed align-self-center">
          <div
            class="font-bold text-orange-500 text-lg"
            *ngIf="ticketForm.dirty"
          >
            You have unsaved changes!
          </div>

          <div
            class="font-bold text-orange-500 text-lg"
            *ngIf="readonly"
          >
            Ticket updated by another user. Refresh before editing.
          </div>
        </div>
      </div>
      <p-toolbar>
        <div class="p-toolbar-group-left flex flex-wrap">
          <div class="col align-self-end">
            <div *ngIf="isMobile">
              <p-tieredMenu
                #menu
                autoZIndex="true"
                [model]="actionItems"
                [popup]="true"
                appendTo="body"
                styleClass="mobileMenuAction"
              ></p-tieredMenu>
              <button
                pButton
                type="button"
                (click)="menu.toggle($event)"
                icon="pi pi-bars"

                styleClass="p-button-secondary"
              ></button>
            </div>
            <div *ngIf="!isMobile">
              <button
                class="p-button-outlined mr-2"
                label="Customers Sign"
                type="button"
                icon="pi pi-pencil"
                (click)="onSign()"
                [disabled]="readonly || disableActions"
                pButton
                pRipple
              ></button>
              <button
                *tjHasRole="'PAYMENT_PROCESS'"
                class="p-button-outlined mr-2"
                label="Pay"
                type="button"
                [ngStyle]="{'color': partner?.payment?.approvalRequired ? 'red' : 'var(--primary-color)'}"
                icon="pi pi-credit-card"
                (click)="onPay()"
                pButton
                pRipple
              ></button>
              <p-splitButton
                label="PDF"
                icon="pi pi-file-pdf"
                [model]="pdfOptions"
                styleClass="p-button-outlined mr-2"
                [hideTransitionOptions]=""
                (onClick)="onPdfAction(TicketPdfOptions.PDF)"
              >
                <p-progressSpinner
                  *ngIf="pdfDownloadSpinner"
                  styleClass="custom-spinner"
                  strokeWidth="1"
                ></p-progressSpinner>
              </p-splitButton>
              <p-confirmDialog key="pdf_to_customer_confirmation_dialog" appendTo="body"></p-confirmDialog>

              <p-splitButton
                label="Print"
                icon="pi pi-print"
                [model]="printOptions"
                styleClass="p-button-outlined mr-2"
                (onClick)="onPrintAction(TicketPrintOptions.PRINT)"
              >
              </p-splitButton>

              <ng-container [ngSwitch]="showButtonDuplicte">
                <ng-container *ngSwitchCase="true">
                  <p-splitButton
                    *ngIf="hasDuplicateAndDuplicatePrivateRoles() && canDuplicate()"
                    label="Duplicate"
                    icon="pi pi-copy"
                    [model]="duplicateOptions"
                    styleClass="p-button-outlined mr-2"
                    (onClick)="onDuplicate()"
                  ></p-splitButton>
              
                  <ng-container *ngIf="hasDuplicatePrivateRole()">
                    <button
                      class="p-button-outlined mr-2"
                      label="Create private duplicate"
                      type="button"
                      (click)="onDuplicatePrivate()"
                      pButton
                      pRipple
                    ></button>
                  </ng-container>
              
                  <ng-container *ngIf="hasDuplicateRole()">
                    <button
                      class="p-button-outlined mr-2"
                      label="Create duplicate"
                      type="button"
                      (click)="onDuplicate()"
                      pButton
                      pRipple
                    ></button>
                  </ng-container>
                </ng-container>
              
                <ng-container *ngSwitchDefault>
                  <button
                    class="p-button-outlined mr-2"
                    label="Create private duplicate"
                    type="button"
                    (click)="onDuplicatePrivate()"
                    pButton
                    pRipple
                  ></button>
                </ng-container>
              </ng-container>
              
              <ng-container *ngIf="ticket.active; else recoverTicket">
                <button
                  class="p-button-outlined mr-2 p-button-danger"
                  label="Delete"
                  type="button"
                  icon="pi pi-trash"
                  (click)="onDeleteTicket()"
                  *tjHasRole="'TICKET_DELETE'"
                  pButton
                  pRipple
                ></button>
              </ng-container>

              <ng-template #recoverTicket>
                <button
                  class="p-button-outlined mr-2 p-button-info"
                  label="Recover"
                  type="button"
                  icon="pi pi-undo"
                  (click)="onRecoverTicket()"
                  *tjHasRole="'TICKET_DELETE'"
                  pButton
                  pRipple
                ></button>
              </ng-template>
            </div>
          </div>
        </div>

        <div class="p-toolbar-group-right">
          <div class="flex align-self-start">
            <div class="col-fixed"
                 *ngIf="isMobile && !readonly && !disableActions && canSaveChanges()">
              <p-splitButton
                [label]="primarySaveAction.label"
                icon="pi pi-save"
                [model]="saveOptions"
                (onClick)="primarySaveAction.command()"
                styleClass="mobileMenuSave"
              ></p-splitButton>
            </div>
            <button
              pButton
              type="button"
              class=" ml-2 p-button"
              label="Refresh page"
              *ngIf="readonly"
              (click)="refreshPage()"
            ></button>

            <div class="flex" *ngIf="!isMobile && !readonly && !disableActions">
              <div class="col-fixed" *ngIf="canSaveChanges()">
                <button
                  pButton
                  [label]="primarySaveAction.label"
                  type="button"
                  [loading]="isSaving"
                  [class]="primarySaveAction.className"
                  (click)="primarySaveAction.command()"
                  [disabled]="primarySaveAction.disabled"
                ></button>
              </div>

              <ng-container *ngFor="let saveOption of saveOptions">
                <div class="col-fixed" *ngIf="saveOption.conditions()">
                  <button
                    class="{{ saveOption.className }}"
                    pButton
                    [label]="saveOption.label"
                    type="button"
                    [loading]="isSaving"
                    (click)="saveOption.command()"
                    [disabled]="saveOption.disabled"
                  ></button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </p-toolbar>
    </div>


    <!-- INFO PANEL -->
    <ng-template #relatedTicketsPanel>
        <p-panel
          header="Related tickets by"
          [collapsed]="isMobile"
          [toggleable]="true"
          styleClass="mb-4">
          <div *ngIf="ticket && ticket.id" class="col">
            <tj-ticket-related [ticket]="ticket"></tj-ticket-related>
          </div>
        </p-panel>
    </ng-template>
    <ng-template #infoPanel>
      <p-panel header="Info" [toggleable]="false" styleClass="mb-4">
        <div class="p-fluid">
          <div class="field grid">
            <label for="status" class="col-12 mb-2">Status</label>
            <p-dropdown
              [disabled]="readonly || isSaving || disableActions"
              (onChange)="onStatusChange($event.value)"
              [(ngModel)]="ticket.status"
              [ngModelOptions]="{ standalone: true }"
              [options]="filteredStatuses$ | async"
              id="status"
              class="col-12"
              *ngIf="canEditStatus()"
            >
              <ng-template let-option pTemplate="selectedItem">
                <div class="status-item">
                  <span class="status-label font-bold">{{ option.label }}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <input pInputText *ngIf="!canEditStatus()" value="{{ticket.status}}" disabled class="ml-2 mr-2">
          </div>
          <div class="field grid">
            <label for="tags" class="col-12 mb-2">Tags</label>
            <tj-tag-combobox
              [blockBuiltin]="true"
              (onAdd)="addTag($event)"
              (onRemove)="removeTag($event)"
              [(ngModel)]="ticket.tags"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="readonly || disableActions || !canChangeTags() || isSaving"
              id="tags"
              class="col-12"
            ></tj-tag-combobox>
          </div>
        </div>
      </p-panel>
    </ng-template>


    <!-- INVOICE PANEL -->
    <ng-template #invoicePanel>
      <p-panel
        header="Invoicing / Estimate"
        styleClass="mb-4"
        [toggleable]="true"
        *tjHasRole="'INVOICE_VIEW_OWNED'"
      >
        <div class="grid align-items-center">
          <div class="lg:col-12 sm:col-12" *ngIf="ticket.invoiceId">
            <a [routerLink]="['/invoice', ticket.invoiceId]"
            >Invoice #{{ ticket.invoiceId }} generated on
              {{ ticket.invoiceCreatedAt | date : configService.dateFormat }}
              Total: {{ticket.invoiceTotalAmount  | currency : 'USD'}}</a
            >
          </div>
          <div class="lg:col-12 sm:col-12" *ngIf="ticket.estimateId">
            <a [routerLink]="['/invoice', ticket.estimateId]"
            >Estimate #{{ ticket.estimateId }} generated on
              {{ ticket.estimateCreatedAt | date : configService.dateFormat }}
              Total: {{ticket.estimateTotalAmount  | currency : 'USD'}}</a
            >
          </div>

        </div>
      </p-panel>
      <p-panel
        header="Invoicing / Estimate"
        styleClass="mb-4"
        [toggleable]="true"
        *tjHasRole="'INVOICE_EDIT'"
      >
        <div class="grid align-items-center">
          <div class="lg:col-12 sm:col-12" *ngIf="ticket.invoiceId">
            <a [routerLink]="['/invoice', ticket.invoiceId]">
              <div class="p-0 col-12 mb-2">Invoice status:
                <span style="border-radius: 15px;"
                      class="pt-1 pb-1 pl-2 pr-2 invoice-status-color-{{ ticket.invoiceStatus?.toLowerCase() || '' }}"
                >
                  {{ ticket.invoiceStatus }}
                </span>
              </div>
              Invoice #{{ ticket.invoiceId }} generated on
              {{ ticket.invoiceCreatedAt | date : configService.dateFormat }}
              Total: {{ticket.invoiceTotalAmount  | currency : 'USD'}}
            </a>
          </div>
          <div class="lg:col-12 sm:col-12" *ngIf="ticket.estimateId">
            <a [routerLink]="['/invoice', ticket.estimateId]"
            >Estimate #{{ ticket.estimateId }} generated on
              {{ ticket.estimateCreatedAt | date : configService.dateFormat }}
              Total: {{ticket.estimateTotalAmount  | currency : 'USD'}}</a
            >
          </div>
          <div class="lg:col-12 sm:col-12">
            <form [formGroup]="quickInvoiceForm">
              <tj-quick-invoice-form
                [readonly]="readonly || disableActions"
                [ticketPartnerStatus]="ticket.ticketPartnerStatus"
                [partner]="ticket.partner"
                [ticketInvoice]="ticketInvoice"
                (invoiceChange)="onInvoiceChange($event)"
                [form]="quickInvoiceForm"
              ></tj-quick-invoice-form>
            </form>
          </div>
          <div class="lg:col-12 sm:col-12 tj-quick-invoice-text">
            <span *ngIf="ticketInvoice"
              >Total: {{ ticketInvoice.subtotal | currency }}</span
            >
          </div>
          <div
            *ngIf="
              ticket.estimateId === null &&
              ticket.status !== TicketStatus.VERIFIED &&
              ticket.status !== TicketStatus.COMPLETED &&
              ticket.status !== TicketStatus.INVOICED
            "
          >
            <button
              class="p-button-secondary"
              label="Generate Estimate"
              pButton
              type="button"
              [loading]="isSavingInvoice"
              [disabled]="isSavingInvoice || isSaving || readonly"
              (click)="onEstimate()"
            ></button>
          </div>
          <div
            *ngIf="
              ticket.estimateId !== null  &&
              ticket.status !== TicketStatus.VERIFIED &&
              ticket.status !== TicketStatus.COMPLETED &&
              ticket.status !== TicketStatus.INVOICED
            "
          >
            <button
              class="p-button-secondary"
              label="Update Estimate Amount"
              pButton
              type="button"
              [loading]="isSavingInvoice"
              [disabled]="isSavingInvoice || isSaving || readonly"
              (click)="onEstimate()"
            ></button>
          </div>
          <div
            class="col-fixed tj-quick-invoice-button"
            *ngIf="
              (ticket.status === TicketStatus.VERIFIED ||
                ticket.status === TicketStatus.COMPLETED) &&
              ticket.invoiceId === null
            "
          >
            <button
              pButton
              label="Generate & Send Invoice"
              type="button"
              class="button primary"
              [loading]="isSavingInvoice"
              [disabled]="isSavingInvoice || isSaving || readonly"
              (click)="onInvoice()"
            ></button>
          </div>

          <div
            class="col-fixed tj-quick-invoice-button"
            *ngIf="ticket.invoiceId !== null"
          >
            <button
              pButton
              label="Update Invoice Amount"
              type="button"
              class="button primary"
              [loading]="isSavingInvoice"
              [disabled]="isSavingInvoice || isSaving || readonly"
              (click)="onInvoice()"
            ></button>
            <button
              *ngIf="isInvoiceChange()"
              pButton
              label="Cancel"
              type="button"
              class=" ml-2 button primary"
              (click)="cancelInvoiceChange()"
            ></button>
          </div>
        </div>
      </p-panel>
    </ng-template>


    <!-- COMMUNICATION PANEL -->
    <ng-template #communicationPanel>
      <p-panel
      header="Communications"
      [collapsed]="isMobile && !ticket.hasComment"
      [toggleable]="true"
      styleClass="mb-4"
    >
      <div *ngIf="ticket && ticket.id" class="col">
        <tj-ticket-chats
          [ticketId]="ticket.id"
          [partnerId]="ticket.partner?.id"
          [technicians]="ticket.technicians"
        ></tj-ticket-chats>
      </div>
    </p-panel>
    </ng-template>

    <!-- TICKET EDIT PANEL -->
    <ng-template #ticketEditPanel>
      <form [formGroup]="ticketForm" autocomplete="off" novalidate>
        <tj-ticket-form
          [form]="ticketForm"
          [areas]="areas"
          [readonly]="readonly"
          [disableActions]="disableActions"
          (statusChange)="onStatusChange($event)"
          (reportChange)="onReportChange($event)"
          (onSaveAndNotify)="onSaveAndNotify()"
          [isSaving]="isSaving"
          [sendToPayFormClicked]="sendToPayFormClicked"
          [isPayFormed]="isPayFormed"
          (sendToPayForm)="onSendToPayFormClick()"
          (updatePayForm)="updatePayForm()"
          [ticket]="ticket"
        ></tj-ticket-form>
      </form>
    </ng-template>

    <!-- TICKET EDIT CUSTOMER SIGNATURE -->
    <ng-template #customerSignature>
      <div class="text-center mt-4 mb-4">
        <button
          class="p-button-outlined mr-2 px-4 py-3"
          label="Customers Sign"
          type="button"
          icon="pi pi-pencil"
          (click)="onSign()"
          pButton
          pRipple
        ></button>
      </div>
    </ng-template>

    <!-- CUSTOMER PANEL -->
    <ng-template #customerPanel>
      <p-panel header="Customer" [toggleable]="true" styleClass="mb-4">
        <ng-template pTemplate="icons">
          <button
            *tjHasRole="'TICKET_EDIT_CUSTOMER'"
            pButton
            [disabled]="readonly || disableActions"
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-plain p-button-text"
            (click)="onEditCustomer()"
          ></button>
          <button
            *ngIf="canUpdateCustomerAddressAndAddContacts()"
            pButton
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-plain p-button-text"
            (click)="onCustomerDetailsUpdate()"
          ></button>
          <p-menu
            #menu
            id="config_menu"
            [model]="items"
            [popup]="true"
          ></p-menu>
        </ng-template>
        <tj-customer-details
          [readonly]="readonly"
          [ticketId]="ticket.id"
          [customer]="customer"
          [mainContactPerson]="mainCustomerContactPerson"
          [disabled]="canConfirmCustomerAddress || disableActions"
          [ticketServiceDate]="ticket.serviceDate"
        ></tj-customer-details>

        <tj-ticket-edit-customer-modal
          (onSave)="saveCustomer()"
          (hide)="onEditCustomerModalHide()"
          [visible]="editCustomerVisible"
        ></tj-ticket-edit-customer-modal>
      </p-panel>
    </ng-template>


    <!-- PARTNER PANEL -->
    <ng-template #partnerPanel>
      <p-panel
        [toggleable]="true"
        styleClass="mb-4"
      >
        <ng-template pTemplate="header">
          <div class="flex flex-row justify-content-center align-items-center">
            <span>Account / Requested by</span>
            <ng-container *ngIf="partner?.combinedInvoice">
              <span class="pi pi-paperclip p-2 ml-1 text-blue-800"
                    pTooltip="This account accepts combined invoices" tooltipPosition="top"></span>
            </ng-container>
            <ng-container *ngIf="partner?.payment?.netTermType ===  'CREDIT_CARD_ON_FILE'">
              <button
                [ngStyle]="{'background-color': partner?.payment?.approvalRequired ? 'red' : 'var(--primary-color)'}"
                pButton icon="pi pi-credit-card" class="p-button-sm ml-2" (click)="onPay()"
                pTooltip="This account pays by credit card" tooltipPosition="top"></button>
            </ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="icons">
          <button
            *tjHasRole="'TICKET_EDIT_PARTNER'"
            pButton
            [disabled]="readonly || disableActions"
            type="button"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-plain p-button-text"
            (click)="onEditPartner()"
          ></button>
          <p-menu
            #menu
            id="config_menu"
            [model]="items"
            [popup]="true"
          ></p-menu>
        </ng-template>

        <tj-partner-details
          [partnerBusinessStatus]="ticket.ticketPartnerStatus"
          [mainContactPerson]="mainPartnerContactPerson"
          [partner]="partner"
          [showOnlyMain]="ticket.ticketPartnerStatus === TICKET_PARTNER_STATUS.PRIVATE_BUSINESS"
        ></tj-partner-details>

        <tj-ticket-edit-partner-modal
          (hide)="onEditPartnerModalHide()"
          [visible]="editPartnerVisible"
        ></tj-ticket-edit-partner-modal>
      </p-panel>
    </ng-template>

    <!-- REVISIONS PANEL -->
    <ng-template #revisionsPanel>
      <p-panel header="Revisions" [collapsed]="true" [toggleable]="true" styleClass="mb-4" *tjHasRole="'TICKET_VIEW_REVISION'" (collapsedChange)="revisionCollapsedChange($event)">
        <div
          style="max-height: 455px; overflow-y: scroll"
          *ngIf="ticket.revision"
        >
          <tj-revision [revision]="ticket.revision"></tj-revision>
        </div>
      </p-panel>
    </ng-template>


    <ng-container *ngIf="isMobile">
      <div class="col-12">
        <ng-container *ngTemplateOutlet="partnerPanel"></ng-container>
        <ng-container *ngTemplateOutlet="customerPanel"></ng-container>
        <ng-container *ngTemplateOutlet="infoPanel"></ng-container>
        <ng-container *ngTemplateOutlet="invoicePanel"></ng-container>
        <ng-container *ngTemplateOutlet="ticketEditPanel"></ng-container>
        <ng-container *ngTemplateOutlet="customerSignature"></ng-container>
        <ng-container *ngTemplateOutlet="relatedTicketsPanel"></ng-container>
        <ng-container *ngTemplateOutlet="communicationPanel"></ng-container>
        <ng-container *ngTemplateOutlet="revisionsPanel"></ng-container>
      </div>
    </ng-container>


    <ng-container *ngIf="!isMobile">
      <div class="col-3">
        <ng-container *ngTemplateOutlet="infoPanel"></ng-container>
        <ng-container *ngTemplateOutlet="invoicePanel"></ng-container>
        <ng-container *ngTemplateOutlet="communicationPanel"></ng-container>
        <ng-container *ngTemplateOutlet="relatedTicketsPanel"></ng-container>
      </div>


      <div class="col-6 mt-0">
        <ng-container *ngTemplateOutlet="ticketEditPanel"></ng-container>
      </div>


      <div class="col-3 mt-0">
        <ng-container *ngTemplateOutlet="partnerPanel"></ng-container>
        <ng-container *ngTemplateOutlet="customerPanel"></ng-container>
        <ng-container *ngTemplateOutlet="revisionsPanel"></ng-container>
      </div>
    </ng-container>

  </div>
</div>

<p-dialog [(visible)]="customEmailDialog" [style]="{width: '650px'}" header="Custom email" [modal]="true"
          styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="col-12 text-red-800 text-center font-semibold" *ngIf="isCustomerEmailMissing"> Not able to send as
      Customer Email Address Missing
    </div>
    <form [formGroup]="customEmailForm" *ngIf="customEmailForm"
          (submit)=" !isCustomerEmailMissing ? sendToEmail() : sendPdfToCustomer(getCustomEmailFormControl('option').value)">

      <div class="field">
        <div class="col-12">
          <tj-field label="Email" [control]="getCustomEmailFormControl('email')">
            <input type="text" pInputText formControlName="email"/>
          </tj-field>
        </div>
      </div>

      <div class="flex flex-grow-1 justify-content-end">
        <div>
          <button pButton [disabled]="!customEmailForm.valid" type="submit" label="Send" icon="pi pi-send"></button>
        </div>
      </div>

    </form>
  </ng-template>
</p-dialog>
<p-confirmDialog appendTo="body"></p-confirmDialog>

<p-dialog #signDialog (onHide)="onHideSign()" [(visible)]="customerSignDialog"
          [autoZIndex]="true"

          [responsive]="true"
          blockScroll="true" header="Customer signature and Report">

  <tj-signature-dialog
    [ticketForm]="ticketForm"
    [galleryId]="ticket.galleryId"
    [ticket]="ticket"
    (onSaveReport)="onSaveReport()"
  >
  </tj-signature-dialog>

</p-dialog>


<p-dialog #signDialog (onHide)="onHideCustomerDetailsUpdate()" [(visible)]="customerDetailsUpdateDialog"
          [style]="{width: '650px'}"
          [autoZIndex]="true"
          [responsive]="true"
          blockScroll="true" header="Customer details update">

  <tj-address-contact-dialog
    [readonly]="readonly || disableActions"
    [mainContactPerson]="ticket.mainCustomerContactPerson"
    [customer]="customer"
    (onUpdateCustomerDetails)="onUpdateCustomerDetails($event)"
  >
  </tj-address-contact-dialog>

</p-dialog>


<p-dialog [(visible)]="notifyRouteDialog" [style]="{width: '650px'}"
          [modal]="true"
          [closable]="false"
          closeOnEscape="false"
          styleClass="p-fluid">
  <ng-template pTemplate="content">
    <div class="col-12 font-semibold text-center text-2xl text-red-600 flex justify-content-center align-items-center">
      <i class="pi pi-exclamation-triangle" style="font-size: 5rem;"></i>
      The active route ticket has changed. Review to resend if needed!
    </div>
    <div class="col-12 flex justify-content-end align-items-center">
      <button type="button" pButton class="w-fit" (click)="routeChangeNotificationRequired(false)">Cancel</button>
      <button type="button" pButton class="w-fit ml-2" (click)="routeChangeNotificationRequired(true)">View</button>
    </div>

  </ng-template>
</p-dialog>
<p-confirmDialog key="confirm_pay_form_changes" appendTo="body"></p-confirmDialog>
