import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../api/service/api.service';
import {DomainManagementModel} from '../../../../api/model/domainmanagement/domain.management.model';
import {EntityListResponseModel} from '../../../../api/model/shared/Response.model';

@Component({
  selector: 'tj-domains-list',
  templateUrl: './domain-list.component.html',
})
export class DomainListComponent implements OnInit {

  domains: DomainManagementModel[];

  constructor(private api: ApiService) {
  }

  ngOnInit() {
    this.api.domainManagementApi.findList()
      .subscribe((response: EntityListResponseModel<DomainManagementModel>) => {
        this.domains = response.response;
      });
  }

  openDomain(domain: string) {
    window.open(domain, '_blank')
  }
}
