<div class="p-card p-component py-2">
  <div class="text-center">
    {{text}}
    <ng-container *ngIf="tooltip">
      <i class="pi pi-question-circle" [pTooltip]="tooltip"></i>
    </ng-container>
  </div>
  <p class="text-center">
    <b [ngStyle]="{'color': color}">{{value}}</b>
  </p>
</div>
