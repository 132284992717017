<form novalidate
      autocomplete="off"
      [formGroup]="filterForm"
      (reset)="onSearchReset()"
      (ngSubmit)="onFilter()">
  <div class="grid">
    <div class="col-12">
      <tj-pay-form-search-form [filter]="filter"
                               [form]="filterForm"
                               group="payFormFilter"></tj-pay-form-search-form>
    </div>
    <div class="col-12 py-0 flex">
      <tj-pay-form-search-labels (remove)="onRemoveFilter($event)" [filterForm]="filterForm"
                                 [payFormSearchFilter]="filter"></tj-pay-form-search-labels>
    </div>
    <div class="col-12 align-content-end flex justify-content-between">
      <div class="flex align-items-end">
        <span *ngIf="page">Total: {{page.totalElements}}</span>
      </div>
      <div>
        <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
        <button pButton type="submit" label="Search" icon="pi pi-search"></button>
      </div>
    </div>
    <div class="col-12" *ngIf="stats">
      <p-toolbar styleClass="justify-content-center">
        <div class="grid w-full" *ngIf="stats.payForm">
          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Technician Paid Amount'"
              [value]="stats.payForm.totalPaidAmount || 0 | currency : 'USD'"
            ></tj-metric>
          </div>
          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Technician Paid Cash'"
              [value]="stats.payForm.totalIncomeCash || 0 | currency : 'USD'"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Technician Expense'"
              [value]="stats.payForm.totalTechnicianExpenses || 0 | currency : 'USD'"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Technician Gross Income'"
              [value]="stats.payForm.totalGrossIncome || 0 | currency : 'USD'"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12" *ngIf="stats.technicianCompletionRate">
            <tj-metric
              [text]="'Tech Completion Rate'"
              [color]="stats.companyCompletionRate > stats.technicianCompletionRate.rate ? 'red' : 'black'"
              [value]="(stats.technicianCompletionRate.rate | percent)"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12" *ngIf="stats.technicianCompletionRate">
            <tj-metric
              [text]="'Tech Comparison Ranking'"
              [color]="stats.companyCompletionRate > stats.technicianCompletionRate.rate ? 'red' : 'black'"
              [value]="stats.technicianCompletionRate.position"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12" *ngIf="stats.technicianTurnAroundTime">
            <tj-metric
              [text]="'Tech Response & Turnaround'"
              [color]="stats.technicianTurnAroundTime > 5 ? 'red' : 'black'"
              [value]="stats.technicianTurnAroundTime + ' hours'"
            ></tj-metric>
          </div>

        </div>
        <div class="grid w-full" *ngIf="stats.servicedTickets">

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Private Tickets Served'"
              [value]="(stats.servicedTickets?.privateBusinessTicketsCount || 0) + (stats.servicedTickets?.privateTicketsCount || 0)"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Account Tickets Served'"
              [value]="stats.servicedTickets?.registeredTicketsCount"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Total Tickets Served'"
              [value]="stats.servicedTickets?.totalTicketsCount"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Average Cost Per Completion'"
              [value]="stats.avgCostPerCompletion | currency:'USD'"
            ></tj-metric>
          </div>

          <div class="md:col sm:col-6 col-12">
            <tj-metric
              [text]="'Company Completion Rate'"
              [value]="stats.companyCompletionRate | percent"
            ></tj-metric>
          </div>

        </div>
      </p-toolbar>
    </div>
  </div>
</form>
