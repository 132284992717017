import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {InstructionComponent} from './components/instruction-list/instruction.component';
import {ConfirmPopupModule} from 'primeng/confirmpopup';


@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'instruction',
        component: InstructionComponent,
        data: {}
      },
    ]),
    ConfirmPopupModule
  ],
  declarations: [
    InstructionComponent
  ],
  providers: [],
  exports: [
    InstructionComponent
  ]
})
export class InstructionModule {
}
