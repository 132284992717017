import {Component} from '@angular/core';

@Component({
  selector: 'tj-widget-integration',
  templateUrl: './widget-integration.component.html',
  styleUrls: ['./widget-integration.component.scss']
})
export class WidgetIntegrationComponent {

  constructor() {
  }

}
