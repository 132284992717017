import {Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CommunicationService} from '../service/communication.service';
import {EntityListResponseModel} from '../model/shared/Response.model';
import {DomainManagementModel} from '../model/domainmanagement/domain.management.model';

@Injectable()
export class DomainManagementApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'domains/management';

  findList(): Observable<EntityListResponseModel<DomainManagementModel>> {
    const url = `${this.apiEndpoint}`;
    return this.requestService.get(url).pipe(
      map(response => response as EntityListResponseModel<DomainManagementModel>),
      catchError(this.handleError));
  }

}
