import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {StatisticsChartsComponent} from './statistics-charts.component';
import {CommonModule} from '@angular/common';
import {
  DomainStatsComponent,
  InvoiceAmountStatsComponent,
  TicketsPerAccountStatsComponent,
  TicketsPerStatusStatsComponent
} from './components';
import {HighchartsChartModule} from 'highcharts-angular';


@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    HighchartsChartModule,
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'statistics',
        component: StatisticsChartsComponent,
        data: {
          roles: ['INVOICE_VIEW']
        }
      },
    ])
  ],
  declarations: [
    DomainStatsComponent,
    InvoiceAmountStatsComponent,
    TicketsPerStatusStatsComponent,
    TicketsPerAccountStatsComponent,
    StatisticsChartsComponent
  ],
  providers: [],
  exports: [
    DomainStatsComponent,
    InvoiceAmountStatsComponent,
    TicketsPerStatusStatsComponent,
    TicketsPerAccountStatsComponent,
    StatisticsChartsComponent
  ]
})
export class StatisticsModule {
}
