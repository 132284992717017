import {Component, OnInit} from '@angular/core';
import {ConfigService, TjKeycloakService} from '../core/services';

@Component({
  selector: 'tj-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

  private adminItems: any[] = [
    AppMenuComponent.getTicketsItem(),
    AppMenuComponent.getSchedulerItem(),
    AppMenuComponent.getRoutesItem(),
    AppMenuComponent.getInvoicesItem(),
    AppMenuComponent.getPaymentAndStatsItem(),
    AppMenuComponent.getPayrollItem(),
    AppMenuComponent.getAccountsItem(),
    AppMenuComponent.getTechniciansItem(),
    AppMenuComponent.getLogsAndStatistics(),
    AppMenuComponent.getCommunication(),
    AppMenuComponent.getRequirements(),
    AppMenuComponent.getConfiguration(),
    AppMenuComponent.getScriptAndPlugin(),
    AppMenuComponent.getDomainManagement(),
    AppMenuComponent.getInstructions(),
    AppMenuComponent.getInventory(),
    AppMenuComponent.getReviews(),
    AppMenuComponent.getMarketplace(),
    AppMenuComponent.getRoleManagement(),
    // AppMenuComponent.getLeads(),
    AppMenuComponent.getTracking(),
  ];

  private technicianItems: any[] = [
    AppMenuComponent.getTicketsItem(),
    AppMenuComponent.getRoutesItem(),
    AppMenuComponent.getSchedulerItem(),
    AppMenuComponent.getPayrollItem(),
    AppMenuComponent.getRequirements(),
    AppMenuComponent.getInstructions(),
    AppMenuComponent.getInventory(),
    AppMenuComponent.getReviews(),
    // AppMenuComponent.getTracking(),
    // AppMenuComponent.getCommunication(),
    // AppMenuComponent.getLogsAndStatistics(),
  ];

  private accountItems: any[] = [
    AppMenuComponent.getTicketsItem(),
    AppMenuComponent.getInvoicesItem(),
    AppMenuComponent.getPaymentAndStatsItem(),
    AppMenuComponent.getLogsAndStatistics(),
  ];

  model: any[];

  constructor(public keycloakService: TjKeycloakService,
              private configService: ConfigService) {
  }

  ngOnInit() {
    if (this.keycloakService.hasRole('MENU_VIEW_AS_ADMIN')) {
      this.model = this.adminItems;
    } else if (this.keycloakService.hasRole('MENU_VIEW_AS_ACCOUNT')) {
      this.model = this.accountItems;
    } else if (this.keycloakService.hasRole('MENU_VIEW_AS_TECHNICIAN')) {
      this.model = this.technicianItems;
    }

    if (this.configService.isEnabledTerritory() && this.keycloakService.hasRole('TERRITORY_MANAGEMENT_VIEW')) {
      const items = [
        AppMenuComponent.getAreasAndZipCodes(),
        AppMenuComponent.getTerritoriesManagement()
      ];
      if (this.model.length >= 8) {
        this.model.splice(8, 0, ...items);
      } else {
        this.model.push(...items);
      }
    }
  }

  private static getTicketsItem() {
    return {
      label: 'Tickets / Services',
      routerLink: ['tickets'],
      icon: 'pi pi-ticket',
      requiredRole: ['TICKET_VIEW'],
    };
  }

  private static getInvoicesItem() {
    return {
      label: 'Invoices',
      routerLink: ['invoices'],
      icon: 'pi pi-dollar',
      requiredRole: ['INVOICE_VIEW']
    };
  }

  private static getSchedulerItem() {
    return {
      label: 'Scheduler & Calendar',
      routerLink: ['scheduler/calendar'],
      icon: 'pi pi-clock',
      requiredRole: ['CALENDAR_VIEW']
    };
  }

  private static getRoutesItem() {
    return {
      label: 'Routes & AI Planing',
      routerLink: ['routes/new'],
      icon: 'pi pi-directions',
      requiredRole: ['ROUTES_PLANING_VIEW', 'ROUTES_PLANING_VIEW_ASSIGNED']
    };
  }

  private static getPaymentAndStatsItem() {
    return {
      label: 'Payments & Stats',
      routerLink: ['invoices/stats'],
      icon: 'pi pi-bars',
      requiredRole: ['INVOICE_VIEW']
    };
  }

  private static getPayrollItem() {
    return {
      label: 'Payroll',
      routerLink: ['payforms'],
      icon: 'pi pi-money-bill',
      requiredRole: ['PAYFORM_VIEW']
    };
  }

  private static getAccountsItem() {
    return {
      label: 'Accounts',
      routerLink: ['accounts'],
      icon: 'pi pi-users',
      requiredRole: ['PARTNER_VIEW']
    };
  }

  private static getTechniciansItem() {
    return {
      label: 'Technicians',
      routerLink: ['technicians'],
      icon: 'pi pi-briefcase',
      requiredRole: ['TECHNICIAN_VIEW']
    };
  }

  private static getTracking() {
    return {
      label: 'Tracking & Location',
      routerLink: '#',
      icon: 'pi pi-eye'
    };
  }

  private static getCommunication() {
    return {
      label: 'Communication',
      url: 'https://core4-dal.spectrumvoip.com/webphone/agent-center',
      icon: 'pi pi-comments'
    };
  }

  private static getRequirements() {
    return {
      label: 'Requirements & COI\'s',
      routerLink: ['catalog'],
      icon: 'pi pi-bars',
      requiredRole: ['CATALOG_VIEW']
    };
  }

  private static getConfiguration() {
    return {
      label: 'Configuration & Set-up',
      routerLink: ['configurations', 'main'],
      icon: 'pi pi-cog',
      requiredRole: ['GLOBAL_CONFIG_VIEW_MENU']
    };
  }

  private static getRoleManagement() {
    return {
      label: 'Role Management',
      url: 'https://auth.servicejan.com',
      icon: 'pi pi-user',
      requiredRole: ['ACCESS_MANAGEMENT_VIEW']
    };
  }

  private static getLogsAndStatistics() {
    return {
      label: 'Logs & Statistics',
      routerLink: ['statistics'],
      icon: 'pi pi-chart-bar',
    };
  }

  private static getInstructions() {
    return {
      label: 'Instructions & Docs',
      routerLink: ['instruction'],
      icon: 'pi pi-info-circle',
      requiredRole: ['INSTRUCTION_VIEW']
    };
  }

  private static getInventory() {
    return {
      label: 'Inventory & Supply',
      routerLink: ['inventory'],
      icon: 'pi pi-list',
      requiredRole: ['INVENTORY_VIEW']
    };
  }

  private static getReviews() {
    return {
      label: 'Reviews & Feedback',
      routerLink: ['reviews'],
      icon: 'pi pi-comment',
      requiredRole: ['REVIEW_VIEW']
    };
  }

  private static getScriptAndPlugin() {
    return {
      label: 'Script & Plug-in',
      routerLink: ['widget', 'integration'],
      icon: 'pi pi-user',
    };
  }

  private static getDomainManagement() {
    return {
      label: 'Domain Management',
      routerLink: ['domains', 'management'],
      icon: 'pi pi-inbox',
      requiredRole: ['DOMAIN_MANAGEMENT_VIEW']
    };
  }

  private static getLeads() {
    return {
      label: 'Leads & Posts',
      routerLink: '#',
      icon: 'pi pi-circle-off',
    };
  }

  private static getMarketplace() {
    return {
      label: 'On-Demand Marketplace',
      routerLink: '#',
      icon: 'pi pi-money-bill',
    };
  }

  private static getAreasAndZipCodes() {
    return {
      label: 'Areas And Zip Codes',
      routerLink: ['configurations', 'area', 'zipCodes'],
      icon: 'pi pi-map',
    };
  }

  private static getTerritoriesManagement() {
    return {
      label: 'Territories Management',
      routerLink: ['configurations', 'areas'],
      icon: 'pi pi-table',
    };
  }
}
