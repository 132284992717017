import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'tj-review-form',
  templateUrl: './tj-review-form.component.html',
  styleUrls: ['./tj-review-form.component.scss'],
})
export class TjReviewFormComponent {
  reviewForm: FormGroup;

  @Output() reviewCreated = new EventEmitter<{ name: string; url: string }>();
  @Output() reviewCanceled = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.reviewForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      url: ['', [Validators.required, Validators.pattern(/https?:\/\/.+/)]],
    });
  }

  submitForm() {
    if (this.reviewForm.valid) {
      this.reviewCreated.emit(this.reviewForm.value);
      this.reviewForm.reset();
    }
  }

  cancel() {
    this.reviewCanceled.emit();
    this.reviewForm.reset();
  }
}
