export class StatsRequestModel {
  technicianId: number;
  technicianCompanyName: string;
  startDate;
  endDate;
}

export class StatsModel {
  payForm: PayFormStatsModel;
  servicedTickets: ServicedTicketsStatsModel;
  avgCostPerCompletion: number;
  companyCompletionRate: number;
  technicianTurnAroundTime: number;
  technicianCompletionRate: TechnicianCompletionRate
}

export class PayFormStatsModel {
  totalPaidAmount: number;
  totalIncomeCash: number;
  totalTechnicianExpenses: number;
  totalGrossIncome: number;
}

export class ServicedTicketsStatsModel {
  privateTicketsCount: number;
  privateBusinessTicketsCount: number;
  registeredTicketsCount: number;
  totalTicketsCount: number;
}

export class TechnicianCompletionRate {
  position: number;
  rate: number;
}

