import {AddressPipe, DistancePipe, DurationPipe, EnumPipe, FileSizePipe, FillPipe, KeysPipe} from './pipe';
import {DeactivationConfirmationService, GalleryService, SearchService, TawkService} from './services';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiModule} from '../../api/api.module';
import {
  AddressLinkComponent,
  AddressMapComponent,
  AddressSearchComponent,
  ContactPersonDetailsComponent,
  DatepickerComponent,
  DateRangePickerComponent,
  EmailDetailsComponent,
  ErrorComponent,
  FieldComponent,
  GalleryPreviewComponent,
  GalleryViewerComponent,
  LoadingComponent,
  MapComponent,
  MetricComponent,
  MoneyInputComponent,
  PageHeaderComponent,
  PaginationComponent,
  PaneComponent,
  PartnerComboboxComponent,
  PartnerTypeComboboxComponent,
  PartnerDetailsComponent,
  PercentageInputComponent,
  SecondaryNavComponent,
  SubtitleComponent,
  TagComboboxComponent,
  TechnicianComboboxComponent,
  ActionDetailsComponent,
  TicketServiceDateComponent,
  TicketStatusComponent,
  RevisionComponent,
} from './components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../../core/core.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {NetTermComponent} from '../partner/net-term/net-term.component';
import {NgxPrintModule} from 'ngx-print';
import {NetTermTypePipe} from './pipe/net-term-type.pipe';
import {UploaderModule} from '../uploader/uploader.module';
import {KeyFilterModule} from 'primeng/keyfilter';
import {SignatureComponent} from './components/signature/signature.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SignatureViewerComponent} from './components/signature/signatureview/signature-viewer.component';
import {SignatureDialogComponent} from './components/signature/signaturedialog/signature-dialog.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {DataViewModule} from 'primeng/dataview';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CoreModule,
    CommonModule,
    ApiModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    UploaderModule,
    KeyFilterModule,
    AngularSignaturePadModule,
    SelectButtonModule,
    PdfViewerModule,
    PanelMenuModule,
    OverlayPanelModule,
    ClipboardModule,
    DataViewModule
  ],
  declarations: [
    PaginationComponent,
    FillPipe,
    FileSizePipe,
    EnumPipe,
    NetTermTypePipe,
    KeysPipe,
    DurationPipe,
    DistancePipe,
    AddressPipe,
    LoadingComponent,
    FieldComponent,
    ErrorComponent,
    PaneComponent,
    PageHeaderComponent,
    SecondaryNavComponent,
    DatepickerComponent,
    DateRangePickerComponent,
    MoneyInputComponent,
    PercentageInputComponent,
    TicketStatusComponent,
    RevisionComponent,
    TicketServiceDateComponent,
    PartnerComboboxComponent,
    PartnerDetailsComponent,
    TagComboboxComponent,
    ActionDetailsComponent,
    EmailDetailsComponent,
    TechnicianComboboxComponent,
    PartnerTypeComboboxComponent,
    AddressLinkComponent,
    AddressSearchComponent,
    MapComponent,
    MetricComponent,
    AddressMapComponent,
    ContactPersonDetailsComponent,
    SubtitleComponent,
    GalleryViewerComponent,
    NetTermComponent,
    GalleryPreviewComponent,
    SignatureComponent,
    SignatureViewerComponent,
    SignatureDialogComponent
  ],
  exports: [
    CoreModule,
    CommonModule,
    ContactPersonDetailsComponent,
    ApiModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    PaginationComponent,
    FieldComponent,
    ErrorComponent,
    FillPipe,
    FileSizePipe,
    EnumPipe,
    KeysPipe,
    DurationPipe,
    DistancePipe,
    AddressPipe,
    LoadingComponent,
    PaneComponent,
    PageHeaderComponent,
    SecondaryNavComponent,
    DatepickerComponent,
    DateRangePickerComponent,
    MoneyInputComponent,
    PercentageInputComponent,
    TicketStatusComponent,
    RevisionComponent,
    TicketServiceDateComponent,
    TagComboboxComponent,
    PartnerComboboxComponent,
    PartnerTypeComboboxComponent,
    PartnerDetailsComponent,
    TechnicianComboboxComponent,
    AddressLinkComponent,
    AddressSearchComponent,
    MapComponent,
    MetricComponent,
    SubtitleComponent,
    NetTermComponent,
    PdfViewerModule,
    GalleryViewerComponent,
    GalleryPreviewComponent,
    NgxPrintModule,
    NetTermTypePipe,
    UploaderModule,
    AddressMapComponent,
    SignatureComponent,
    SignatureViewerComponent,
    SignatureDialogComponent,
    DataViewModule
  ],
  providers: [
    SearchService,
    DeactivationConfirmationService,
    GalleryService,
    ConfirmationService,
    TawkService
  ]
})
export class SharedModule {
}
