import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from '../../../../api/model/shared/Page.model';
import {UntypedFormGroup} from '@angular/forms';
import {NotificationService, TjKeycloakService} from '../../../../core/services';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {PayFormSearchService} from '../../services/pay-form-search.service';
import {PayFormSearchFilter} from '../../models/pay-form-search.filter';
import {StatsModel, StatsRequestModel} from '../../../../api/model/stats/stats.model';
import {ApiService} from '../../../../api/service/api.service';

@Component({
  selector: 'tj-pay-form-search',
  templateUrl: './pay-form-search.component.html',
  styleUrls: ['./pay-form-search.component.scss']
})
export class PayFormSearchComponent implements OnInit {
  @Input() filter: PayFormSearchFilter;

  @Input() page: Page<any>;

  stats: StatsModel;

  filterForm: UntypedFormGroup;

  constructor(private notificationService: NotificationService,
              private api: ApiService,
              private keycloakService: TjKeycloakService,
              private payFormSearchService: PayFormSearchService) {
  }

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
    this.updateStats();
  }

  onSearchReset(): void {
    this.filterForm.reset();
    this.payFormSearchService.reset();
    this.updateStats();
  }

  onFilter() {
    const formValue = this.filterForm.value;
    if (this.filterForm.valid) {
      this.payFormSearchService.searchByFormValue(formValue.payFormFilter);
      this.updateStats();
    } else {
      FormHelpers.validateAllFormFields(this.filterForm);
    }
  }

  onRemoveFilter(filter): void {
    this.payFormSearchService.search(filter);
  }

  updateStats() {
    const formValue = this.filterForm.value.payFormFilter || {};
    const {endDate, startDate, technicianCompanyName, technicianId} = formValue;

    const model = new StatsRequestModel();
    model.technicianId = technicianId;
    model.technicianCompanyName = technicianCompanyName;
    model.startDate = startDate;
    model.endDate = endDate;

    this.api.stats.getStats(model)
      .subscribe((stats: StatsModel) => {
          this.stats = stats;
        }
      );
  }


}
