import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CommunicationService} from '../service/communication.service';
import {GalleryUploadResponseModel} from '../../main/shared/models/Gallery.model';
import {InventoryModel} from '../model/inventory/inventory.model';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class InventoryApi extends CommunicationService {

  protected apiEndpoint = this.env.apiEndpoint + 'inventory';

  public getFiles(): Observable<InventoryModel> {
    const url = `${this.apiEndpoint}/files`;
    return this.requestService.get(url).pipe(
      map(response => response.response as InventoryModel),
      catchError(this.handleError));
  }

  public delete(fileId: number): Observable<void> {
    return this.requestService.remove(this.apiEndpoint + '/files/' + fileId).pipe(
      map(
        (response) => {
        }
      ),
      catchError(this.handleError)
    );
  }


  public uploadFile(file: File[]): Observable<GalleryUploadResponseModel> {
    const url = `${this.apiEndpoint}/files/upload`;
    return this.requestService.upload(url, file, undefined);
  }

}
