<p-table #dt
         *ngIf="instruction"
         [value]="instruction.documents"
         [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="isUploading"
         [globalFilterFields]="['fileName','fileType']"
         [paginator]="true">
  <ng-template pTemplate="caption">
    <div class="table-header">
      <div class="grid">
        <div class="col">
          <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                 placeholder="Global Search"/>
        </div>
        <div class="col-fixed" *tjHasRole="'INSTRUCTION_EDIT_ADD_FILE'">
          <p-fileUpload
            #uploader
            [disabled]="isUploading"
            (uploadHandler)="uploadHandler($event.files, uploader)"
            [auto]="true"
            [customUpload]="true"
            [mode]="'basic'"
            [multiple]="true"
            accept=".pdf,.doc,.docx,.xls,.xlsx"
            chooseLabel="Upload documents">
          </p-fileUpload>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="min-width: 14rem">Name</th>
      <th style="min-width: 8rem">Type</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-document>
    <tr class="p-selectable-row">
      <td>
        <span class="p-column-title">Name</span>
        {{ document.fileName }}
      </td>
      <td>
        <span class="p-column-title">Type</span>
        <i [ngClass]="getDocumentIcon(document.extension)" class="mr-2"></i>
        {{ document.fileType }}
      </td>
      <td>
        <p-button *ngIf="document?.extension === '.pdf'"
                  (onClick)="viewPdf(document)"
                  class="ml-3"
                  icon="pi pi-eye"
        ></p-button>
        <button
          pButton
          class="ml-4"
          icon="pi pi-download"
          (click)="download(document)"
        ></button>

        <ng-container *tjHasRole="'INSTRUCTION_EDIT_REMOVE_FILE'">
          <button
            pButton
            class="p-button-danger ml-4"
            icon="pi pi-trash"
            (click)="confirmRemove($event, document)"
          ></button>
        </ng-container>

      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">Instruction is empty</td>
    </tr>
  </ng-template>
</p-table>

<p-confirmPopup></p-confirmPopup>
<p-dialog [(visible)]="displayPdfDialog" header="View PDF" [modal]="true" [style]="{ width: '90vw', height: '90vh' }">
  <ng-container *ngIf="document">
    <div *ngIf="isLoading" class="loading-spinner-container">
      <p-progressSpinner styleClass="loading-spinner"></p-progressSpinner>
    </div>
    <pdf-viewer
      [src]="document.url"
      [render-text]="true"
      [original-size]="true"
      [show-all]="true"
      (error)="onPdfError($event)"
      (on-progress)="loading($event)"
      style="display: block; width: 100%; height: 100%;">
    </pdf-viewer>
  </ng-container>
</p-dialog>
