import {Component, Input, OnInit} from "@angular/core";
import * as Highcharts from 'highcharts';
import {InvoiceStatsItem} from '../../../invoice/models/invoice.stats';

@Component({
    selector: 'tj-invoice-amount-stats-chart',
    templateUrl: './invoice-amount-stats.component.html',
})
export class InvoiceAmountStatsComponent implements OnInit {

    private readonly TOP = 20;

    @Input('items') items: InvoiceStatsItem[];

    constructor() {
    }

    ngOnInit(): void {
      this.showInvoiceStats();
    }

  private showInvoiceStats() {
        const topN = this.items.filter(it => it.partner !== null)
            .sort((a, b) => (b.paid.amount) - (a.paid.amount))
            .slice(0, this.TOP);

        const categories = topN.map(it => it.partner.name ? it.partner.name : 'PRIVATE / 3rd Party');

        const paid = topN.map(it => it.paid.amount);
        const unpaid = topN.map(it => it.totalUnpaid.amount);

        const chartOptions = this.getOptions(categories, paid, unpaid);

        Highcharts.chart('invoice-amount', chartOptions);
    }

    private getOptions(categories: string[], paid: number[], unpaid: number[]): Highcharts.Options {
        return {
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Invoice paid / unpaid stats'
            },
            xAxis: {
                categories: categories,
                crosshair: true,
                accessibility: {
                    description: 'Accounts / 3rd Party'
                }
            },
            yAxis: [
                {
                    // main yAxis
                }, {
                    minPadding: 0,
                    maxPadding: 0,
                    opposite: true,
                    labels: {
                        format: '{value}%'
                    }
                }
            ],
            tooltip: {
                valueSuffix: ' $'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [
                {
                    name: 'Paid',
                    // @ts-ignore
                    data: paid
                },
                {
                    name: 'Unpaid',
                    // @ts-ignore
                    data: unpaid
                }
            ]
        };
    }

}
