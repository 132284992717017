<div class="grid" [formGroup]="currentFormGroup" *ngIf="currentFormGroup">
  <ng-container formArrayName="contactPersons" *ngFor="let contactPerson of fcContactPersons.controls;let i = index;">
    <div class="col-12 contact-person-container">
      <ng-container [formGroupName]="i">
        <div class="col-12">
          <div class="grid align-items-end">
            <div class="col-9">
              <tj-field label="Contact person name" [control]="contactPerson.controls.name">
                <input pInputText type="text" placeholder="Contact person name" formControlName="name"
                       (keydown)="onKeyDown($event)">
              </tj-field>
            </div>
            <div class="col-fixed checkbox-is-main" *ngIf="canChangeMainContactPerson">
              <tj-field [control]="fcIsMain">
                <p-checkbox formControlName="isMain" label="Main?" binary="true"
                            (onChange)="onMainContactPersonChange(i)"></p-checkbox>
              </tj-field>
            </div>
            <div class="col-fixed" *ngIf="fcContactPersons.controls.length > minCount">
              <button (click)="onRemoveContactPerson(i)" class="p-button-danger" icon="pi pi-trash" pButton
                      label="Person"></button>
            </div>
          </div>
        </div>
        <div *ngIf="canChangeReceivers" class="col-12">
          <div class="grid">
            <div class="col-fixed">
              <p-checkbox binary="true" formControlName="notificationReceiver" label="Receive all notifications"></p-checkbox>
            </div>
            <div class="col-fixed">
              <p-checkbox binary="true" formControlName="pdfReceiver" label="Receive invoice PDF"></p-checkbox>
            </div>
            <div class="col-fixed">
              <p-checkbox binary="true" formControlName="receiveNotificationsRequestedRep" label="Receive Notifications by Requested Rep Only"></p-checkbox>
            </div>
            <div class="col-fixed">
              <p-checkbox binary="true" formControlName="receiveInvoiceRequestedRep" label="Receive invoice by Requested Rep Only"></p-checkbox>
            </div>
            <div class="col-fixed">
              <p-checkbox binary="true" formControlName="receivesComments" label="Received all comments"></p-checkbox>
            </div>
            <div class="col-fixed">
              <p-checkbox binary="true" formControlName="receivesCommentsIfRep" label="Receive comments by Requested Rep Only"></p-checkbox>
            </div>
          </div>
        </div>
        <ng-container formArrayName="contacts"
                      *ngFor="let contact of contactPerson.controls.contacts.controls; let j = index; let last = last;">
          <ng-container [formGroupName]="j">
            <div class="col-12">
              <div class="grid align-items-end">
                <div class="lg:col-3 col-8">
                  <tj-field label="Contact" [control]="contact.controls.type">
                    <p-dropdown [options]="configService.contactTypes" (onChange)="changeContactType($event, i, j)"
                                appendTo="body"
                                placeholder="Contact type"
                                formControlName="type"></p-dropdown>
                  </tj-field>
                </div>
                <div class="lg:col-4 col-8">
                  <tj-field label="Number/Email" [control]="contact.controls.value">
                    <input pInputText [type]="contact.controls.type.value === 'EMAIL' ? 'email' : 'text'"
                           placeholder="Number/Email *" formControlName="value"
                           (keydown)="onKeyDown($event, contact.controls.type.value)"
                           (paste)="onPaste($event, contactPerson.controls.contacts.controls[j])">
                  </tj-field>
                </div>
                <div class="col-fixed">
                  <button class="p-button-danger" icon="pi pi-trash" pButton
                          *ngIf="contactPerson.controls.contacts.controls.length > 1"
                          (click)="onRemoveContact(i, j)"></button>
                </div>
                <div class="col-fixed">
                  <button *ngIf="last" class="p-button-success" icon="pi pi-plus" pButton
                          (click)="onAddContact(i)"></button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <div class="col-12" *ngIf="maxContactPersons > fcContactPersons.controls.length">
    <div class="grid justify-content-end">
      <div class="col-fixed">
        <button (click)="onAddContactPerson()" class="p-button-success" icon="pi pi-plus" label="Add additional contact person" pButton
                type="button">
        </button>
      </div>
    </div>
  </div>
</div>
