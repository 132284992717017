import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {TjReviewsListComponent} from './components';
import { TjReviewFormComponent } from './components/review-form/tj-review-form.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'reviews',
        component: TjReviewsListComponent,
      }
    ]),
    ClipboardModule
  ],
  declarations: [

    TjReviewsListComponent,
      TjReviewFormComponent
  ],
  providers: []
})
export class ReviewsModule {
}
