import {Area} from '../Area.model';

export class CalendarEventModel {
  id: number;
  technicianId: number;
  date: string | Date;
  note: string;
  intermediateAreas?: Area[];
  updatedAt: string;
  modifiedBy: string | Date;
}
