import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeactivationConfirmationService} from '../../../shared/services';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, NotificationService} from '../../../../core/services';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-technician-list',
  templateUrl: './technician-list.component.html',
  styleUrls: ['./technician-list.component.scss'],
  providers: [DeactivationConfirmationService]
})
export class TechnicianListComponent implements OnInit {

  @Input() technicians: TechnicianMinimalListItem[];

  @Output() selectTechnician = new EventEmitter();

  @Output('onSort') onSortEmitter = new EventEmitter();

  constructor(private api: ApiService,
              private configService: ConfigService,
              private deactivationConfirmationService: DeactivationConfirmationService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
  }

  onSelect($event) {
    this.selectTechnician.emit($event);
  }

  onDeactivateTechnician(technicianId: number): void {
    this.deactivationConfirmationService.confirm(() => {
      this.api.technician.deactivate(technicianId).subscribe(() => {
        this.technicians = this.technicians.filter(technician => technician.id !== technicianId);
        this.technicians = [...this.technicians];
        this.onSelect(null);
        this.configService.removeTechnicianOnList(technicianId);
        this.notificationService.success(Messages.TECHNICIAN_DEACTIVATED);
      });
    });
  }

  onRecoverTechnician(technicianId: number) {
    this.deactivationConfirmationService.confirm(() => {
      this.api.technician.recover(technicianId).subscribe(() => {
        const recoveredTechnician = this.technicians.find(technician => technician.id === technicianId);
        this.configService.recoverTechnicianOnList(recoveredTechnician);
        this.technicians = this.technicians.filter(technician => technician.id !== technicianId);
        this.technicians = [...this.technicians];
        this.onSelect(null);
        this.notificationService.success(Messages.TECHNICIAN_RECOVERED);
      });
    }, true);
  }

  sort($event: any) {
    this.onSortEmitter.emit($event);
  }

  getSpecialityTooltip(speciality: string): string {
    return speciality.length > 30 ? speciality : '';
  }

  getTruncatedSpeciality(speciality: string): string {
    return speciality.length > 30 ? `${speciality.substring(0, 30)} ...` : speciality;
  }
}
