import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Vacation} from '../../../../api/model/Vacation.model';
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'tj-vacation-list',
  templateUrl: './vacation-list.component.html',
  styleUrls: ['./vacation-list.component.scss']
})
export class VacationListComponent {

  @Input('vacations') vacations: Vacation[];

  @Output('onDelete') deleteEmitter: EventEmitter<number> = new EventEmitter<any>();
  @Output('onSort') onSortEmitter: EventEmitter<any> = new EventEmitter<any>();

  public env = environment;

  constructor() {
  }

  onDeleteZipCode(vocationId: number) {
    this.deleteEmitter.emit(vocationId);
  }

  onSort($event: any) {
    this.onSortEmitter.emit($event);
  }

}

