import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {PartnerMinimalListItem} from '../../../../api/model/PartnerLine.model';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {TicketFilter} from '../../../../api/model/TicketFilter';
import {Tag} from '../../../../api/model/Tag.model';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {environment} from '../../../../../environments/environment';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'tj-ticket-search-chips',
  templateUrl: './ticket-search-chips.component.html',
  styleUrls: ['./ticket-search-chips.component.scss']
})
export class TicketSearchChipsComponent implements OnInit, OnChanges {

  private readonly ticketStatusMap: Map<string, string> = new Map<string, string>();

  partnersMap: { [id: number]: PartnerMinimalListItem } = {};

  techniciansMap: { [id: number]: TechnicianMinimalListItem } = {};

  @Input() ticketFilter: TicketFilter;
  @Input() filterForm: UntypedFormGroup;

  @Output() remove = new EventEmitter();

  tags: Tag[] = [];

  filterNames = {
    id: 'Id',
    externalReferenceCode: 'PO/WO/SALES',
    customer: 'Customer',
    address: 'Address',
    area: 'Area',
    content: 'Content',
    contact: 'Contact',
    comment: 'Comment',
    status: 'Status',
    invoiceId: 'Invoice #',
    invoiceAmount: 'Invoice amount',
    partnerId: 'Account',
    technicianId: 'Technician',
    tags: 'Tags',
    privateRegisteredAccount: 'Private 3rd Party /None Account',
    serviceDate: 'Service date',
    serviceDateBefore: 'Service date as of',
    createDate: 'Create date',
    contactDate: 'Contact date',
    category: 'Type of Service/Lead/Note',
    notPrintedByAdmin: 'Not Printed',
    notPrintedByTechnician: 'Not Printed',
    notPrintedByPartner: 'Not Printed',
  };

  protected readonly environment = environment;

  constructor(public config: ConfigService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticketFilter && changes.ticketFilter.currentValue.tags) {
      this.config.tags$.subscribe(value => {
        const arr = [];
        changes.ticketFilter.currentValue?.tags.forEach((item) => {
          if (value.length) {
            const itemTag = value.find(x => x.id === parseFloat(item));
            arr.push(itemTag);
          }
        });
        this.tags = arr;
      });
    }
  }

  ngOnInit() {
    this.config.ticketStatusMap.forEach(it => {
      this.ticketStatusMap.set(it.value as string, it.label);
    });
    this.config.partners$.subscribe(value => {
      this.partnersMap = value.reduce((acc, partner) => {
        acc[partner.id] = partner;
        return acc;
      }, this.partnersMap);
    });
    this.config.technicians$.subscribe(value => {
      this.techniciansMap = value.reduce((acc, technician) => {
        acc[technician.id] = technician;
        return acc;
      }, this.techniciansMap);
    });

  }

  onRemoveChip(filterName: string) {
    const updatedFilter = {...this.ticketFilter};
    if (updatedFilter[filterName]) {
      updatedFilter[filterName] = null;
      if (this.filterForm && this.filterForm.get(`ticketFilter.${filterName}`)) {
        this.filterForm.get(`ticketFilter.${filterName}`).setValue(null);
      }
    }

    this.remove.emit(updatedFilter);
  }

  getStatusValue(status: TicketStatus): string {
    return this.ticketStatusMap.get(status);
  }

}
