<div>
  <h1>Integration Guide: Embedding the AFS Widget</h1>

  <h2>Overview</h2>
  <p>This guide provides step-by-step instructions for integrating the AFS Widget into any website. The widget is
    initialized via JavaScript and requires a placeholder element in your HTML where the widget will render.</p>

  <h2>Steps for Integration</h2>

  <h3>1. Add the Placeholder</h3>
  <p>Identify where you want the AFS Widget to appear on your website. Add the following HTML element to act as a
    placeholder:</p>
  <code>&lt;div id="afs-widget"&gt;&lt;/div&gt;</code>

  <h3>2. Include the JavaScript File</h3>
  <p>Ensure the <code>script.js</code> file containing the AFS Widget logic is accessible. Include the file in your HTML
    as follows:</p>
  <code>&lt;script src="https://tj-widget.s3.amazonaws.com/script.js"&gt;&lt;/script&gt;</code>

  <h3>3. Initialize the Widget</h3>
  <p>After loading the <code>script.js</code> file, initialize the widget with the following script:</p>
  <code>
    &lt;script&gt;
    AFSWidget.init(&lbrace;
    afsWidget: 'afs-widget',
    apiKey: 'your-api-key'
    &rbrace;);
    &lt;/script&gt;
  </code>

  <h2>Request an API Key</h2>
  <p>Currently, API keys can be obtained by contacting us directly. Please provide the following details when requesting
    an API key:</p>
  <ul>
    <li>A list of all domains where the widget will be deployed</li>
  </ul>
  <p>Send your request to <a href="mailto:info@servicejan.com">info@servicejan.com</a>, and we will process it as
    soon as possible.</p>

</div>
