import {TicketUpdateCheckService} from '../../services/ticket-update-check.service';
import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Ticket} from '../../../../api/model/Ticket.model';
import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {Customer} from '../../../../api/model/Customer.model';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Partner} from '../../../../api/model/Partner.model';
import {ApiService} from '../../../../api/service/api.service';
import {GalleryUploadResponseModel} from '../../../shared/models/Gallery.model';
import {Messages} from '../../../../common/messages';
import {BreadcrumbService} from '../../../../layout/service/app.breadcrumb.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ComponentCanDeactivate} from '../../../../core/guards/pending-change.guard';
import {BehaviorSubject, Observable, Subject, timer} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {FileUpload} from 'primeng/fileupload';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {
  ImageFormatConverterComponent
} from '../../../uploader/components/image-converter/image-format-converter.component';
import {ImageCompressionService} from '../../services/image.compression.service';


@Component({
  selector: 'tj-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.scss'],
  providers: [TicketEditContextService, TicketUpdateCheckService]
})
export class TicketEditComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  private isFileShowing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly destroy$ = new Subject<void>();

  ticketId: number;

  ticket: Ticket;

  customer: Customer;

  partner: Partner;

  isMobile = false;

  mainCustomerContactPerson: ContactPerson;

  mainPartnerContactPerson: ContactPerson;

  ticketPartnerStatus: TicketPartnerStatus;

  newGalleryId: number;

  ticketForm: UntypedFormGroup;

  isUploading = false;

  readonly = false;
  disableActions = false;

  constructor(private api: ApiService,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private context: TicketEditContextService,
              private breadcrumbService: BreadcrumbService,
              private fb: UntypedFormBuilder,
              private layoutService: LayoutService,
              public config: ConfigService,
              private imageFormatConverter: ImageFormatConverterComponent,
              private ticketUpdateCheckService: TicketUpdateCheckService,
              private imageCompressionService: ImageCompressionService,
              private keycloakService: TjKeycloakService) {
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.ticketForm.dirty) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.ticketId = parseInt(params.id, 10);

      this.context.ticket.subscribe(ticket => {
        if (ticket) {
          this.ticket = ticket;
          let label = `Ticket #${this.ticket.id}`;

          if (!this.isMobile) {
            // Show external reference code only on desktop as there is not enough space on mobile
            if (this.ticket.externalReferenceCode) {
              label = `${label} / ${this.ticket.externalReferenceCode}`;
            }
          }

          if (this.ticket.ticketPartnerStatus) {
            label = `${label}`;
            if (ticket.ticketPartnerStatus === 'PRIVATE' || ticket.ticketPartnerStatus === 'PRIVATE_BUSINESS') {
              this.breadcrumbService.addText('PRIVATE');
              this.breadcrumbService.addTextClass('private');
            }
          }
          this.breadcrumbService.addBreadcrumb({label});
          this.breadcrumbService.enableCopyToClipboard();

          this.initTicketUpdateCheckService();
        }
      });
      this.context.partner.subscribe(partner => this.partner = partner);
      this.context.customer.subscribe(customer => this.customer = customer);
      this.context.ticketPartnerStatus.subscribe(ticketPartnerStatus => this.ticketPartnerStatus = ticketPartnerStatus);
      this.context.mainCustomerContactPerson.subscribe(mainCustomerContactPerson => this.mainCustomerContactPerson = mainCustomerContactPerson);
      this.context.mainPartnerContactPerson.subscribe(mainPartnerContactPerson => this.mainPartnerContactPerson = mainPartnerContactPerson);
      this.context.loadTicket(this.ticketId);
      this.ticketForm = this.fb.group({});
    });

    this.isMobile = this.layoutService.isMobile();
  }

  public initTicketUpdateCheckService() {
    // Listen if other user changes the ticket
    this.ticketUpdateCheckService.initTicketUpdateTracking(this.ticket)
      .subscribe(() => {
        this.showTicketHasBeenChangedDialog();
        this.disableSaving();
      });
  }

   public uploadHandler(files: File[], uploader: FileUpload): void {
    this.isFileShowing$.next(true);
    this.isUploading = true;
    this.imageFormatConverter.convertImages(files)
      .then(async (convertedFiles: File[]) => {
        await this.decreaseImagesSizes(convertedFiles);
        this.api.gallery.uploadFile(convertedFiles, this.ticket.galleryId, this.ticket.id)
          .subscribe({
            next: (galleryUploadResponseModel: GalleryUploadResponseModel) => {
              if (galleryUploadResponseModel.success) {
                this.newGalleryId = galleryUploadResponseModel.response.galleryId;
                this.context.ticketGalleryEmmit.emit(galleryUploadResponseModel.response.galleries);
                this.notificationService.success(Messages.FILE_UPLOADING);
              } else {
                this.notificationService.error(Messages.FILE_SIZE);
              }
            },
            error: (err) => {
              this.notificationService.error(`File upload failed: ${err.message || 'Unknown error'}`);
            },
            complete: () => {
              this.isUploading = false;
              uploader.clear();
              timer(3000)
                .subscribe(() => {
                  this.isFileShowing$.next(false);
                });
            }
          });
      })
      .catch((error) => {
        this.notificationService.error('Error converting images:' + error);
        this.isUploading = false;
        this.isFileShowing$.next(false);
      });
  }

  private showTicketHasBeenChangedDialog() {
    this.notificationService.warning(Messages.TICKET_HAS_BEEN_CHANGED);
  }

  private disableSaving() {
    this.readonly = true;
    this.disableActions = true;
  }

  canEditAttachments(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_ATTACHMENTS');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.ticketUpdateCheckService.destroy();
    this.breadcrumbService.addText(null);
    this.breadcrumbService.addTextClass(null);
  }

  private isImage(file: File) {
    const validTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp'];
    return validTypes.indexOf(file.type) !== -1;
  }

  private async decreaseImagesSizes(convertedFiles: File[]) {
    try {
      for (let i = 0; i < convertedFiles.length; i++) {
        if (this.isImage(convertedFiles[i]) && convertedFiles[i].size > 1024 * 1024) {
          convertedFiles[i] = await this.imageCompressionService.compressImage(convertedFiles[i]);
        }
      }
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  }
  }
