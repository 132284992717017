export class Colors {
  public static BLACK = '#000';
  public static BLUE = 'blue';
  public static RED = 'red';
  public static TRANSPARENT = 'transparent';
  public static TECHNICIAN_OFF = '#e0e0e0';
  public static TECHNICIAN_STOPS_COUNT_NONE = '#F7F7F7';
  public static TECHNICIAN_STOPS_COUNT_LOW = '#E8FCE9';
  public static TECHNICIAN_STOPS_COUNT_HIGH = '#CCEBC8';
  public static TECHNICIAN_STOPS_WORK_CRITICAL = '#f6bbc1';
}
