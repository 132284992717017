import {
  AreaApi,
  ChatApi,
  CommentApi,
  DomainManagementApi,
  GalleryApi,
  InventoryApi,
  InstructionApi,
  InvoiceApi,
  PartnerApi,
  PayFormApi,
  PaymentApi,
  ReviewsApi,
  SchedulerApi,
  SignatureApi,
  StatsApi,
  TagApi,
  TechnicianApi,
  TicketApi,
  UserApi,
  ZipCodeApi,
  CatalogApi
} from '../endpoints';
import {Injectable} from '@angular/core';
import {GlobalConfigApi} from '../endpoints/global-config.api';
import {RouteApi} from '../endpoints/route.api';
import {GeocodingApi} from '../endpoints/geocoding.api';

@Injectable()
export class ApiService {
  constructor(
    public invoice: InvoiceApi,
    public inventory: InventoryApi,
    public instruction: InstructionApi,
    public catalog: CatalogApi,
    public payForm: PayFormApi,
    public stats: StatsApi,
    public ticket: TicketApi,
    public partner: PartnerApi,
    public technician: TechnicianApi,
    public gallery: GalleryApi,
    public signatureApi: SignatureApi,
    public domainManagementApi: DomainManagementApi,
    public geocodingApi: GeocodingApi,
    public chat: ChatApi,
    public payment: PaymentApi,
    public user: UserApi,
    public zipCode: ZipCodeApi,
    public comment: CommentApi,
    public globalConfig: GlobalConfigApi,
    public routeApi: RouteApi,
    public area: AreaApi,
    public reviews: ReviewsApi,
    public scheduler: SchedulerApi,
    public tag: TagApi
  ) {
  }
}
