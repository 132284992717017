import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Technician} from '../../../../api/model/Technician.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {ApiService} from '../../../../api/service/api.service';
import {NotificationService} from '../../../../core/services';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Messages} from '../../../../common/messages';

@Component({
  selector: 'tj-technician-edit',
  templateUrl: './technician-edit.component.html',
  styleUrls: ['./technician-edit.component.scss']
})
export class TechnicianEditComponent implements OnInit, OnDestroy {

  @Output('onSave') technicianSave = new EventEmitter<Technician>();

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private _technician: Technician;

  @Input() set technician(technician: Technician) {
    this._technician = technician;
  }

  get technician() {
    return this._technician;
  }

  form: UntypedFormGroup;

  isSaving = false;

  constructor(private api: ApiService,
              private fb: UntypedFormBuilder,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.form = this.fb.group({});
  }

  onSave() {
    if (this.form.valid) {
      this.isSaving = true;
      const formValues = this.form.value;
      const valueToSave = {...this.technician, ...formValues} as Technician;
      if (this.isEditMode()) {
        this.update(valueToSave);
      } else {
        this.create(valueToSave);
      }
    } else {
      FormHelpers.validateAllFormFields(this.form);
    }
  }

  private isEditMode(): boolean {
    return !!this.technician?.id;
  }

  private create(newTechnician: Technician): void {
    this.api.technician.create(newTechnician)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (technician: Technician) => this.handleResponse(Messages.TECHNICIAN_CREATED, technician),
        errorMessage => this.handleError(errorMessage)
      );
  }

  private update(updatedTechnician: Technician): void {
    this.api.technician.update(updatedTechnician)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        technician => this.handleResponse(Messages.TECHNICIAN_UPDATED, technician),
        errorMessage => this.handleError(errorMessage)
      );
  }

  private handleResponse(successMessage: string, technician: Technician): void {
    this.technicianSave.emit(technician);
    this.isSaving = false;
    this.form.reset();
    this.notificationService.success(successMessage);
  }

  private handleError(errorMessage: string): void {
    this.isSaving = false;
    this.notificationService.error(errorMessage);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
