<div class="grid flex-column">
  <div class="col-12 no-print" *tjHasRole="'PAYFORM_EDIT'">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <ng-container *ngIf="selectedPayForm && technician">
          Pay form of {{technician.name}}
          <ng-container *ngIf="technician.personnelCode">
            / {{technician.personnelCode}}
          </ng-container>
          <ng-container *ngIf="technician.companyName">
            / {{technician.companyName}}
          </ng-container>
          from {{selectedPayForm.startDate | date: configService.dateFormat}}
          to {{selectedPayForm.endDate | date: configService.dateFormat}}
        </ng-container>
        &nbsp;
        <span *ngIf="selectedPayForm && technician" class="font-bold">
          <span *ngIf="technician.payType === 'COMMISSION_BASED'">
            Commission based - {{technician.percent}}%
            <strong
              *ngIf="technician.percent !== selectedPayForm.percent"> ( PayForm calculate commission based - {{selectedPayForm.percent}}
              % )</strong>
          </span>
          <span
            *ngIf="technician.payType === 'HOUR_BASED'">Hour unit based - {{ technician.unit | currency: 'USD' }}</span>
        </span>
      </div>
      <div class="p-toolbar-group-right flex-wrap">
        <button pButton pRipple (click)="showPrint()" *ngIf="selectedPayForm?.id" class="mr-2 mb-2" icon="pi pi-print"
                  label="Print"></button>
          <button pButton pRipple  (click)="onAddForNext()" *ngIf="selectedPayForm?.id" class="mr-2 mb-2"
                  label="Add next pay form"></button>
          <button pButton pRipple (click)="onDeletePayForm()" *ngIf="selectedPayForm?.id" icon="pi pi-trash" class="p-button-danger ml-2 mb-2"></button>
      </div>
    </p-toolbar>
  </div>
  <div class="col-12">
    <ng-container *ngIf="selectedPayForm">
      <tj-pay-form-edit
        [payFormId]="selectedPayForm.id"
        (onUpdate)="refresh($event)"
        [payForm]="selectedPayForm"></tj-pay-form-edit>
    </ng-container>
  </div>
</div>
<p-confirmDialog appendTo="body" key="confirmDeletion"></p-confirmDialog>
