import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {DomainListComponent} from './components/domain-list/domains-list.component';
import {WidgetIntegrationComponent} from './components/widget-integration/widget-integration.component';


@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'domains/management',
        component: DomainListComponent,
        data: {
          roles: ['INVOICE_VIEW']
        }
      },
      {
        path: 'widget/integration',
        component: WidgetIntegrationComponent,
      },
    ])
  ],
  declarations: [
    WidgetIntegrationComponent,
    DomainListComponent
  ],
  providers: [],
  exports: [
    WidgetIntegrationComponent,
    DomainListComponent
  ]
})
export class DomainmanagementModule {
}
